
import { defineComponent, computed } from 'vue';
export default defineComponent({
  name: 'TableBox',
  props: {
    showDeleteButton: Boolean,
    deleteType: {
      type: String,
      default: 'danger',
    },
    deleteText: {
      type: String,
      default: '批量删除',
    },
    showEditButton: Boolean,
    editType: {
      type: String,
      default: 'default',
    },
    editText: {
      type: String,
      default: '批量修改',
    },
    pagination: {
      type: [Object, Boolean],
      default: () => {
        return {
          current: 1,
          total: 10,
        };
      },
    },
  },
  emits: ['page-change'],
  setup(props: any) {
    return {
      current: computed(() => {
        return props.pagination.current;
      }),
    };
  },
});
