import { RouteRecordRaw } from 'vue-router';

const router: Array<RouteRecordRaw> = [
  {
    path: '/merchant/list',
    name: 'merchantList',
    component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/list/list.vue'),
    meta: {
      tabName: '商家管理',
      keepAlive: true,
    },
  },
  {
    path: '/merchant/wallet/detail',
    name: 'merchantWalletDetail',
    component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/components/walletInfo.vue'),
    meta: {
      tabName: '商家钱包明细',
      keepAlive: true,
    },
  },
];

export default router;
