import { Realtime } from "leancloud-realtime";

class IM {
    IMClient: any;
    IMClientID: any;
    realtime: any;
    constructor() {
        this.IMClient = null;
        this.IMClientID = null;

    }

    init() {
        this.realtime = new Realtime({
            appId: "d78Jlzy3oFaFqPEJzVUa594y-gzGzoHsz",
            appKey: "1wQL4ApnOs6ABfi8BINO8Wjq",
            server: "https://lean.pojudian.com"
        });

        this.IMlogin("admin89867828172621")
    }

    //创建聊天室
    createChatRoom(name: string) {
        return this.IMClient.createChatRoom({ name });
    }

    //获取聊天列表
    getchatRecord() {
      return this.IMClient.getQuery().find();

    }



    //登录IM
    IMlogin(userId: string) {
        const self = this;
        if (userId) {
            this.IMClientID = `um_${userId}`;
            this.realtime
                .createIMClient(this.IMClientID)
                .then(function (tom: any) {
                    // 成功登录
                    console.log('登录成功')
                    self.IMClient = tom;
                })
                .catch(console.error);
        }
    }
}

export default new IM();
