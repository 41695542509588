import {  RouteRecordRaw } from 'vue-router';

const router:Array<RouteRecordRaw> = [
  {
    path: '/site/pc',
    name: 'sitePc',
    component: () => import(/* webpackChunkName: "site" */'@/views/site/pc.vue'),
    meta: {
      tabName: 'pc设置',
      keepAlive: true,
    },
  },
];

export default router
