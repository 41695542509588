import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      urls: [
        {
          url: '/workbench',
          name: 'workbench',
          title: '工作台',
          cannotDel: true,//是否能删除此tab
          selected: true,//是否选中
          keepAlive: true,//是否使用缓存keep-alive
        },
      ],
    };
  },
  getters: {
    keepAliveViews(state: any) {
      return state.urls.filter((x:any) => x.keepAlive).map((x:any) => x.name);
    },
  },
  mutations: {
    addUrl(state, payload) {
      //判断是否已经存在
      if (!state.urls.some((x:any) => x.url == payload.url)) {
        state.urls.push(payload);
      }
      //设置当前url为选中
      state.urls.forEach((element: any) => {
        element.selected = element.url == payload.url;
      });
    },
    delUrl(state, payload) {
      state.urls.splice(payload.index, 1);
    },
    empty(state, payload) {
      state.urls = [{
        url: '/workbench',
        name: 'workbench',
        title: '工作台',
        cannotDel: true,//是否能删除此tab
        selected: true,//是否选中
        keepAlive: true,//是否使用缓存keep-alive
      }];
    },
    refreshUrl(state, payload) {
      state.urls[payload.index].keepAlive = payload.keepAlive;
    },
  },
  actions: {},
  modules: {},
});
