
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons-vue';
// <CaretUpOutlined />
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'SearchBox',
  components: { CaretUpOutlined, CaretDownOutlined },
  props: {
    showConfirmType: {
      type: Boolean,
      default: true,
    },
    showTag: {
      type: Boolean,
      default: false,
    },
    isMore: {
      type: Boolean,
      default: false,
    },
    rightConfirmType: {
      type: String,
      default: 'danger',
    },
    rightConfirmText: {
      type: String,
      default: '新增',
    },
    showCancelType: {
      type: Boolean,
      default: false,
    },
    rightCancelType: {
      type: String,
      default: 'default',
    },
    rightCancelText: {
      type: String,
      default: '导入',
    },
  },
  setup() {
    const showMore = ref(false);
    return { showMore };
  },
});
