import { RouteRecordRaw } from 'vue-router';

const router: Array<RouteRecordRaw> = [
  {
    path: '/order/list',
    name: 'orderList',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/list/list.vue'),
    meta: {
      tabName: '交易订单',
      keepAlive: true,
    },
  },
  {
    path: '/order/refundList',
    name: 'refundList',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/refund/list.vue'),
    meta: {
      tabName: '退款订单',
      keepAlive: true,
    },
  },
  {
    path: '/coupon/order/list',
    name: 'couponOrderList',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/coupon/list.vue'),
    meta: {
      tabName: '优惠券订单',
      keepAlive: true,
    },
  },
  {
    path: '/order/detail',
    name: 'orderDetail',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/details/detail.vue'),
    meta: {
      tabName: '订单详情',
      keepAlive: true,
    },
  },
];

export default router;
