import { RouteRecordRaw } from 'vue-router';

const coupon: Array<RouteRecordRaw> = [
  {
    path: '/site/router',
    name: 'siteRouter',
    component: () => import(/* webpackChunkName: "site" */ '@/views/system/router/List.vue'),
    meta: {
      tabName: '路由管理',
      keepAlive: true,
    },
  },
  {
    path: '/site/company',
    name: 'siteCompany',
    component: () => import(/* webpackChunkName: "company" */ '@/views/system/company/List.vue'),
    meta: {
      tabName: '企业管理',
      keepAlive: true,
    },
  },
  {
    path: '/site/role',
    name: 'siteRole',
    component: () => import(/* webpackChunkName: "company" */ '@/views/system/role/List.vue'),
    meta: {
      tabName: '角色列表',
      keepAlive: true,
    },
  },
  {
    path: '/site/staff',
    name: 'siteStaff',
    component: () => import(/* webpackChunkName: "company" */ '@/views/system/staff/List.vue'),
    meta: {
      tabName: '员工列表',
      keepAlive: true,
    },
  },
  {
    path: '/site/area',
    name: 'siteArea',
    component: () => import(/* webpackChunkName: "company" */ '@/views/system/area/List.vue'),
    meta: {
      tabName: '区域管理',
      keepAlive: true,
    },
  }
];

export default coupon;
