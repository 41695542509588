import { RouteRecordRaw } from 'vue-router';

const router: Array<RouteRecordRaw> = [
  {
    path: '/npc/category',
    name: 'npcCategory',
    component: () => import(/* webpackChunkName: "npc" */ '@/views/npc/category/index.vue'),
    meta: {
      tabName: '游戏分类',
      keepAlive: false,
    },
  },
  {
    path: '/npc/list',
    name: 'npcList',
    component: () => import(/* webpackChunkName: "npc" */ '@/views/npc/list/index.vue'),
    meta: {
      tabName: '陪玩管理',
      keepAlive: false,
    },
  },
  {
    path: '/npc/wallet/info',
    name: 'npcWalletInfo',
    component: () => import(/* webpackChunkName: "npc" */ '@/views/npc/walletInfo/walletInfo.vue'),
    meta: {
      tabName: '陪玩钱包明细',
      keepAlive: false,
    },
  },
  {
    path: '/npc/skill',
    name: 'npcSkill',
    component: () => import(/* webpackChunkName: "npc" */ '@/views/npc/skill/index.vue'),
    meta: {
      tabName: '技能管理',
      keepAlive: false,
    },
  },
  {
    path: '/npc/group',
    name: 'npcTeam',
    component: () => import(/* webpackChunkName: "npcTeam" */ '@/views/npc/team/List.vue'),
    meta: {
      tabName: '队伍管理',
      keepAlive: false,
    },
  },
];

export default router;
