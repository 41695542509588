import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import IM from './util/IM';

import MessageWebSocket from './util/ws';

//引入插件
import antUse from './plugins/antUse';
const app = createApp(App);

//引入icon
import '@/style/icon.less';
import '@/style/reset.less';

const appConfig: any = app.config;
appConfig.productionTip = false;

app.config.globalProperties.$selectHandleStop = function (e: any) {
  e.stopPropagation();
};

//初始化IM
IM.init();



if (process.env.NODE_ENV === "production") {
  MessageWebSocket.initWebSocket();
}


//注入antd
antUse(app);

app.use(router);
app.use(store);

app.mount('#app');
