import { RouteRecordRaw } from 'vue-router';

const router: Array<RouteRecordRaw> = [
  {
    path: '/finance/record',
    name: 'financeRecord',
    component: () => import(/* webpackChunkName: "coupon" */ '@/views/finance/record/record.vue'),
    meta: {
      tabName: '交易记录',
      keepAlive: true,
    },
  },
  {
    path: '/finance/apply',
    name: 'financeApply',
    component: () => import(/* webpackChunkName: "coupon" */ '@/views/finance/apply/list.vue'),
    meta: {
      tabName: '提现申请',
      keepAlive: true,
    },
  },
];

export default router;
