import storage from 'store';
import { notification } from 'ant-design-vue';
// const wxURL = import.meta.env.MODE === "production" ? "ws://47.109.88.14:8011" : "ws://47.109.88.14:9011";
//process.env.VUE_APP_BASE_URL
class MessageWebSocket {
  wsServer: any;
  callback: Function | undefined;
  onMessage: Function | undefined;
  constructor() {
    this.wsServer = 'ws://47.109.88.14:8010/leancloud/conversation/ws';
  }

  read(callback: Function) {
    this.callback = callback;
  }

  initWebSocket() {
    const self = this;
    console.log(storage.get('ACCESS_TOKEN'));

    if (storage.get('ACCESS_TOKEN')) {
      console.log(this.wsServer);

      const ws: any = new WebSocket(this.wsServer, [storage.get('ACCESS_TOKEN')]);
      ws.onopen = function () {
        console.log('open ...');
        this.callback && this.callback();
      };

      ws.onmessage = function (evt: any) {
        console.log('接受到消息', evt);
        const ret: any = JSON.parse(evt.data);
        console.log(ret);
        if(ret.code === 201){
          return false
        }

        notification['info']({
          message: '有新的聊天消息',
          description: JSON.parse(ret.data.content)._lctext,
        });
        self.onMessage && self.onMessage(ret);
      };

      ws.onclose = function () {
        setTimeout(function () {
          //没连接上会一直重连，设置延迟避免请求过多
          self.initWebSocket();
        }, 2000);
      };
    }
  }
}

export default new MessageWebSocket();
