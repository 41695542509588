
import { defineComponent, computed, ref } from 'vue';
export default defineComponent({
  name: 'DrawerBox',
  props: {
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '30%',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    submitText: {
      type: String,
      default: '提交',
    },
    showSubmit: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: '取消',
    },
    showBottom: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['drawer-submit', 'update:visible'],
  setup(props, { emit }) {
    const show = computed({
      get() {
        return props.visible;
      },
      set(val) {
        emit('update:visible', val);
      },
    });

    //抽屉确认
    const submitLoading = ref(false);
    function haeldSubmit() {
      submitLoading.value = true;
      emit('drawer-submit', () => {
        submitLoading.value = false;
      });
    }

    //抽屉取消
    function haeldCancel() {
      emit('update:visible', false);
    }
    return { show, haeldCancel, submitLoading, haeldSubmit };
  },
});
