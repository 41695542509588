
import { defineComponent } from 'vue';
import { ConfigProvider } from 'ant-design-vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import moment from 'moment';
import 'moment/dist/locale/zh-cn';
moment.locale('en');
export default defineComponent({
  components: { AConfigProvider: ConfigProvider },
  data() {
    return { zhCN };
  },
});
