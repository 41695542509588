import { RouteRecordRaw } from 'vue-router';
const router: Array<RouteRecordRaw> = [
  {
    path: '/team/list',
    name: 'teamList',
    component: () => import(/* webpackChunkName: "team" */ '@/views/team/List.vue'),
    meta: {
      tabName: '队伍列表',
      keepAlive: true,
    },
  },
  {
    path: '/message/list',
    name: 'messageList',
    component: () => import(/* webpackChunkName: "team" */ '@/views/message/List.vue'),
    meta: {
      tabName: '聊天列表',
      keepAlive: true,
    },
  },
];

export default router;
