import { message, Descriptions, Image, Alert, Tabs, Form, Input, Button, Divider, Switch, Layout, Row, Radio, Col, Tag, Menu, Select, Table, Pagination, InputNumber, Drawer, Dropdown } from 'ant-design-vue';

import SearchBox from '@/components/search-box/index.vue';
import TableBox from '@/components/table-box/index.vue';
import DrawerBox from '@/components/drawer-box/index.vue';
export default function antUse(app: { config: any; use: Function; component: Function }): void {
  // 全局挂载
  app.config.globalProperties.$message = message;

  const useList = [Form, Image, Alert, Descriptions, Tabs, Dropdown, Input, Button, Divider, Switch, Layout, Radio, Menu, Tag, Row, Col, Select, Table, Drawer, Pagination, InputNumber];
  useList.forEach((item) => {
    app.use(item);
  });
  app.component(SearchBox.name, SearchBox);
  app.component(DrawerBox.name, DrawerBox);
  app.component(TableBox.name, TableBox);
}
