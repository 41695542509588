import member from './member';
import merchant from './merchant';
import npc from './npc';
import order from './order';
import site from './site';
import team from './team';
import coupon from './coupon';
import finance from './finance';
import manage from './manage';
import company from './company';

export const adminRouter = [...member,...company, ...merchant, ...npc, ...order, ...site, ...team, ...coupon, ...finance,...manage];
