

import {  RouteRecordRaw } from 'vue-router';
const router:Array<RouteRecordRaw> = [
  {
    path: '/manage/role',
    name: 'manageRole',
    component: () => import(/* webpackChunkName: "manage" */ '@/views/manage/role.vue'),
    meta: {
      tabName: '角色管理',
      keepAlive: true,
    },
  },
  {
    path: '/manage/department',
    name: 'manageDepartment',
    component: () => import(/* webpackChunkName: "manage" */ '@/views/manage/department.vue'),
    meta: {
      tabName: '部门管理',
      keepAlive: false,
    },
  },
  {
    path: '/manage/menu',
    name: 'manageMenu',
    component: () => import(/* webpackChunkName: "manage" */ '@/views/manage/menu.vue'),
    meta: {
      tabName: '菜单管理',
      keepAlive: false,
    },
  },
];

export default router;
