import { RouteRecordRaw } from 'vue-router';

const router: Array<RouteRecordRaw> = [
  {
    path: '/member/player',
    name: 'teamPlayer',
    component: () => import(/* webpackChunkName: "member" */ '@/views/member/player.vue'),
    meta: {
      tabName: '玩家管理',
      keepAlive: true,
    },
  },
];

export default router;
