import { RouteRecordRaw } from 'vue-router';

const coupon: Array<RouteRecordRaw> = [
  {
    path: '/coupon/list',
    name: 'couponList',
    component: () => import(/* webpackChunkName: "coupon" */ '@/views/coupon/list/list.vue'),
    meta: {
      tabName: '优惠券管理',
      keepAlive: true,
    },
  },
  {
    path: '/coupon/user/list',
    name: 'couponUserList',
    component: () => import(/* webpackChunkName: "coupon" */ '@/views/coupon/record/user.vue'),
    meta: {
      tabName: '用户领取列表',
      keepAlive: true,
    },
  },
  {
    path: '/coupon/shop/list',
    name: 'couponShopList',
    component: () => import(/* webpackChunkName: "coupon" */ '@/views/coupon/record/shop.vue'),
    meta: {
      tabName: '商家领取列表',
      keepAlive: true,
    },
  },
];

export default coupon;
