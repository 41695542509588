import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '../store';
import { adminRouter } from './modules/index';
import storage from 'store';
import {routerCheck} from '@/api/user'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Home.vue'),
    redirect: '/admin',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },

  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/index.vue'),
    redirect: '/workbench',
    children: [
      {
        path: '/workbench',
        name: 'workbench',
        component: () => import(/* webpackChunkName: "admin" */ '../views/workbench.vue'),
      },
      {
        path: '/403',
        name: '403',
        component: () => import(/* webpackChunkName: "error" */ '../views/result/403.vue'),
      },
      ...adminRouter,
    ],
  },
];

const whitelist = ['/login','/403',"/workbench"];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});
router.beforeEach((to, from, next) => {
  // 判断是否登录
  if (whitelist.indexOf(to.path) === -1 && !storage.get('ACCESS_TOKEN')) {
    next('/login');
    return;
  }

  if(whitelist.includes(to.path)){
    next();
    return;
  }

  routerCheck(to.path).then(({code}:any)=>{
    if( code === 200){
      next();
    }
  }).catch(()=>{
    return;
  })

  if(to.fullPath !== '/admin' && to.fullPath !== '/login' && to.fullPath !== '/workbench'){
    store.commit('addUrl', {
      url: to.fullPath,
      name: to.name,
      title: to.meta.tabName,
      keepAlive: to.meta.keepAlive,
    });
  }

});

export default router;
