import request from './axios';

export const ManageLogin = (data: any) => {
  return request({
    url: '/manage/user/login',
    method: 'post',
    data: data,
  });
};

//路由检查
export const routerCheck = (RouteUrl: any) => {
  return request({
    url: '/manage/route/auth/check',
    method: 'get',
    params: {RouteUrl},
  });
};

//退出登录
export const loginout = () => {
  return request({
    url: '/manage/user/loginout',
    method: 'post',
  });
};

//更改密码
export const passwordUpdate = (data: any) => {
  return request({
    url: '/manage/user/password/update',
    method: 'post',
    data:data
  });
};




export const manageMenu = () => {
  return request({
    url: '/manage/index/menu',
    method: 'get'
  });
};





interface searchSkill {
  "IsNpc": string|number;
  "Mobile": string|number;
  "NickName": string|number;
  "PageSize": string|number;
  "PageIndex": string|number;
}
export const manageList = (params: searchSkill) => {
  return request({
    url: '/user/list',
    method: 'get',
    params: params,
  });
};

export const updateUser = (params: any) => {
  return request({
    url: `/user/${params.id}/update`,
    method: 'put',
    data: {DistributionRatio:params.ratio}
  });
};


